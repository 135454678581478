import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { getSettingByKeyValue } from '@/utils/common';
import { getRequestedSettings } from '@/store/features/commonSlice';

interface ShopNowProps {
  // data: any;
}
const ShopNow: React.FC<ShopNowProps> = () => {
  const imageBaseS3Url = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;
  const requestedSettings = useSelector(getRequestedSettings);
  const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');
  return (
    <>
      <section className={styles.mettresssection}>
        <div className="home-container-1600">
          <div className={styles.mettressinner}>
            <Link
              href="/mattress"
              className={`${styles['mettressimg']} ${styles['mettressimg-off']}`}
            >
              <Image
                src={
                  imageBaseS3Url +
                  '/images/home-new1/new-mattress-banner.jpg?v=' +
                  imgVer
                }
                alt="Mattress"
                title="Mattress"
                width={895}
                height={410}
                loading="lazy"
                quality={75}
              />
            </Link>
            <Link href="/home-temple" className={styles.mettressimg}>
              <Image
                src={
                  imageBaseS3Url +
                  '/images/home-new1/recliner-banner.jpg?v=' +
                  imgVer
                }
                alt="Home Temple"
                title="Home Temple"
                width={895}
                height={410}
                loading="lazy"
                quality={75}
              />
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopNow;
